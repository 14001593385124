import React from "react";

import Grid from '@mui/material/Grid2';

import Box from "@mui/material/Box";

// Using PostsFeed Filters
import TickerFilter from "../PostsFeed/Components/Filters/TickersFilter";
import TickerSortOrderToggleBtn from "../PostsFeed/Components/Filters/TickerSortOrderToggleBtn";
import AnalystsFilterInput from "../PostsFeed/Components/Filters/AnalystsFilterInput";
import DateRangeFilterInput from "../PostsFeed/Components/Filters/DateRangeFilterInput";

import ToggleSearchBarButton from "./ToggleSearchBarButton";
import { FilterInputOrderBy, FilterInputSelectStatus } from "../PostsFeed/Components/Filters/FiltersInputs";

const FiltersBar: React.FC = () => {
  return (
    <Grid
      container
      wrap="nowrap"
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{ color: "white", flexWrap: 'wrap', p: '5px 0' }}
    >

      {/* ToggleSearchBarButton component to control the search bar visibility */}
      <Box>
        <ToggleSearchBarButton />
      </Box>

      {/* Various filter components for the Posts Feed */}
      <Box sx={{ mx: 1 }}>
        <FilterInputSelectStatus />
      </Box>


      <Box>
        <TickerFilter />
      </Box>

      <Box>
        <FilterInputOrderBy />
      </Box>

      <Box sx={{ display: 'flex', mx: 1 }}>
        <DateRangeFilterInput />
      </Box>

      <Box>
        <AnalystsFilterInput />
      </Box>

      <Box sx={{ mx: 1 }}>
        <TickerSortOrderToggleBtn />
      </Box>
    </Grid>
  );
};

export default FiltersBar;

