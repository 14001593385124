import React from 'react';
import ReactDOM from 'react-dom/client';

// import './index.css';
// import './Components/Chat/chat.css';
// import './legacy_styles.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Auth0Config } from './Auth0Configurations';
import ErrorBoundary from './Components/Utils/ErrorBoundary';
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";


console.log('NODE_ENV => ', process.env.NODE_ENV);

// replace console.* for disable log on production
if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'prod') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
} else {
  loadDevMessages();
  loadErrorMessages();
}

console.log('REACT_APP_ENVIRONMENT => ', process.env.REACT_APP_ENVIRONMENT);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <BrowserRouter>
        <Auth0Config>
          <App />
        </Auth0Config>
      </BrowserRouter>
    </React.StrictMode>
  </ErrorBoundary >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
