import React from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
  SelectProps, SelectChangeEvent, ListSubheader, FormControlProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DATE_ORDER_BY } from '../../Filters/FiltersProvider';

// Styled FormControl for consistent styling
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

// Type definitions using discriminated unions for mutual exclusivity between `label` and `CustomInputLabel`
interface WithLabel {
  label: string; // Requires `label` prop
  CustomInputLabel?: never; // `CustomInputLabel` should not be present
}

interface WithCustomInputLabel {
  label?: never; // `label` should not be present
  CustomInputLabel: React.ReactNode; // Requires `CustomInputLabel` prop
}

type InputSelectOrderByProps = (WithLabel | WithCustomInputLabel) & {
  orderDatesBy: DATE_ORDER_BY;
  onOrderByChange: (event: SelectChangeEvent<unknown>) => void;
  CustomSelectComponent?: React.ComponentType<SelectProps>; // Optional custom Select component
  StyledFormControlProps?: FormControlProps; // New prop for additional FormControl properties
};

const InputSelectOrderBy: React.FC<InputSelectOrderByProps> = ({
  orderDatesBy,
  onOrderByChange,
  label,
  CustomInputLabel,
  CustomSelectComponent,
  StyledFormControlProps, // Destructure the new prop
}) => {
  // Use either the custom Select component or the default Select
  const SelectComponent = CustomSelectComponent || Select;

  // Determine the labelId to be used in the Select
  let labelId = "default-orderby-label-id"; // Fallback value for `labelId`

  // If CustomInputLabel is provided, extract its `id` prop if defined
  if (React.isValidElement(CustomInputLabel) && CustomInputLabel.props.id) {
    labelId = CustomInputLabel.props.id;
    label = CustomInputLabel.props.children;
  }

  return (
    // Spread the `StyledFormControlProps` onto the StyledFormControl component
    <StyledFormControl  {...StyledFormControlProps}>
      {/* Use the provided `CustomInputLabel` or render a default InputLabel based on `label` prop */}
      {CustomInputLabel ? (
        CustomInputLabel
      ) : (
        <InputLabel htmlFor={labelId} id={labelId} >
          {label}
        </InputLabel>
      )}

      <SelectComponent
        id={labelId}
        value={orderDatesBy}
        onChange={onOrderByChange} // Use the provided onChange handler
        {...(label && { label })} // Include `label` prop only if `label` is present
      >
        {/* Render grouped menu items */}
        <ListSubheader>Last Updated / Modify Date</ListSubheader>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.UPDATED_AT_ASC}>
          mAscending
        </MenuItem>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.UPDATED_AT_DESC}>
          mDescending
        </MenuItem>

        <ListSubheader>Original Date</ListSubheader>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.NEWS_DATE_ASC}>
          oAscending
        </MenuItem>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.NEWS_DATE_DESC}>
          oDescending
        </MenuItem>

        <ListSubheader>Created Date</ListSubheader>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.CREATE_AT_ASC}>
          cAscending
        </MenuItem>
        <MenuItem sx={{ pl: 4 }} value={DATE_ORDER_BY.CREATE_AT_DESC}>
          cDescending
        </MenuItem>
      </SelectComponent>
    </StyledFormControl>
  );
};

export default InputSelectOrderBy;
