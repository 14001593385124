import React, { useState, useEffect } from 'react';
import { Box, Typography, Slider, Grid2 as Grid } from '@mui/material';
import { useThemeContext } from '../../ThemesMui/ThemesConfigurations';

const EditFontSettings: React.FC = () => {
  const { theme, updateThemeOptions } = useThemeContext();

  const getFontSize = (): number => (typeof theme.typography === 'function' ? 16 : Number(theme.typography?.postTitle?.fontSize) || 16);
  const getFontWeight = (): number => (typeof theme.typography === 'function' ? 400 : Number(theme.typography?.postTitle?.fontWeight) || 400);

  const getPostContentFontSize = (): number => (typeof theme.typography === 'function' ? 16 : Number(theme.typography?.postContent?.fontSize) || 16);
  const getPostContentFontWeight = (): number => (typeof theme.typography === 'function' ? 400 : Number(theme.typography?.postContent?.fontWeight) || 400);

  const [postTitleFontSize, setPostTitleFontSize] = useState<number>(getFontSize());
  const [postTitleFontWeight, setPostTitleFontWeight] = useState<number>(getFontWeight());
  const [postContentFontSize, setPostContentFontSize] = useState<number>(getPostContentFontSize());
  const [postContentFontWeight, setPostContentFontWeight] = useState<number>(getPostContentFontWeight());

  const handlePostTitleFontSizeChange = (event: Event, newValue: number | number[]) => {
    const newFontSize = newValue as number;
    setPostTitleFontSize(newFontSize);

    if (typeof theme.typography !== 'function') {
      updateThemeOptions({
        typography: {
          postTitle: {
            fontSize: newFontSize,
            fontWeight: postTitleFontWeight,
          },
        },
      });
    }
  };

  const handlePostTitleFontWeightChange = (event: Event, newValue: number | number[]) => {
    const newFontWeight = newValue as number;
    setPostTitleFontWeight(newFontWeight);

    if (typeof theme.typography !== 'function') {
      updateThemeOptions({
        typography: {
          postTitle: {
            fontSize: postTitleFontSize,
            fontWeight: newFontWeight,
          },
        },
      });
    }
  };

  const handlePostContentFontSizeChange = (event: Event, newValue: number | number[]) => {
    const newFontSize = newValue as number;
    setPostContentFontSize(newFontSize);

    if (typeof theme.typography !== 'function') {
      updateThemeOptions({
        typography: {
          postContent: {
            fontSize: newFontSize,
            fontWeight: postContentFontWeight,
          },
        },
      });
    }
  };

  const handlePostContentFontWeightChange = (event: Event, newValue: number | number[]) => {
    const newFontWeight = newValue as number;
    setPostContentFontWeight(newFontWeight);

    if (typeof theme.typography !== 'function') {
      updateThemeOptions({
        typography: {
          postContent: {
            fontSize: postContentFontSize,
            fontWeight: newFontWeight,
          },
        },
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Post title font size:</Typography>
          <Slider
            value={postTitleFontSize}
            min={10}
            max={30}
            step={1}
            valueLabelDisplay="auto"
            onChange={handlePostTitleFontSizeChange}
            sx={{ width: '200px' }}
          />
          <Typography variant='postTitle' sx={{ mt: 1 }}>{postTitleFontSize} px</Typography>
        </Box>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Post title font weight:</Typography>
          <Slider
            value={postTitleFontWeight}
            min={100}
            max={900}
            step={100}
            valueLabelDisplay="auto"
            onChange={handlePostTitleFontWeightChange}
            sx={{ width: '200px' }}
          />
          <Typography variant='postTitle' sx={{ mt: 1 }}>{postTitleFontWeight}</Typography>
        </Box>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Post content font size:</Typography>
          <Slider
            value={postContentFontSize}
            min={10}
            max={30}
            step={1}
            valueLabelDisplay="auto"
            onChange={handlePostContentFontSizeChange}
            sx={{ width: '200px' }}
          />
          <Typography variant='postContent' sx={{ mt: 1 }}>{postContentFontSize} px</Typography>
        </Box>
      </Grid>

      <Grid size={{ xs: 12 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>Post content font weight:</Typography>
          <Slider
            value={postContentFontWeight}
            min={100}
            max={900}
            step={100}
            valueLabelDisplay="auto"
            onChange={handlePostContentFontWeightChange}
            sx={{ width: '200px' }}
          />
          <Typography variant='postContent' sx={{ mt: 1 }}>{postContentFontWeight}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditFontSettings;
