import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_POSTS_TOTAL } from '../../../../Querys';

import { useFeedsState } from '../../Providers/FeedsStateProvider';
import { useFilters } from '../Filters/FiltersProvider';
import { postQuerySerializer, usePostsFeed } from '../../Providers/PostsProvider';

// Define el tipo para los filtros y las variables de feed
type FiltersType = any; // Reemplaza con el tipo específico de tus filtros
type FeedVariablesType = { [key: string]: any }; // Reemplaza con el tipo específico si tienes propiedades fijas en las variables

// Define el tipo de retorno para el hook personalizado
interface UsePostsCountResult {
  totalPosts: number;
}

// Hook personalizado para manejar la lógica de la consulta
function usePostsCount(filters: FiltersType, getFeedVariables: () => FeedVariablesType): UsePostsCountResult {
  const { data: { postsCount: { total } } = { postsCount: { total: 0 } }, refetch } = useQuery(GET_POSTS_TOTAL, {
    variables: {
      ...postQuerySerializer(filters),
      ...getFeedVariables()
    },
    fetchPolicy: "cache-and-network", // Utiliza el caché y luego realiza la consulta en segundo plano
    onError: () => {
      console.error("Error fetching posts count.");
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    totalPosts: total, // Devuelve el valor de la consulta o el caché
  };
}

const Counter: React.FC = React.memo(() => {
  const [filters] = useFilters();
  const { getFeedVariables } = useFeedsState();
  const { posts } = usePostsFeed();
  const { totalPosts } = usePostsCount(filters, getFeedVariables);

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      mx: 2
    }}>
      <Typography variant="body1">{posts.length}/{totalPosts}</Typography>
    </Box>
  );
});

export default Counter;
