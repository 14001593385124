import React, { useCallback } from 'react';
import { Grid2 as Grid } from '@mui/material';
import StockValueInput from '../../commons/StockValueInput';
import useStockValue from '../../../../../Services/NewsGraphqlAPI/Hooks/useStockValue';
import { Post } from '../../../../../Types/Post';
import { usePostEditor } from '../PostEditorProvider';

const PostEditorStockValue: React.FC = () => {
  const { draftPost, setDraftPost: setPost, post } = usePostEditor(); // Usamos el contexto para obtener los valores

  const { ticker, news_date, stock_value_change } = draftPost;
  const cachedTicker = post?.ticker?.ticker;

  const isCachedPostDifferent =
    post == null ||
    (ticker != null && (cachedTicker == null || cachedTicker !== ticker.ticker)) ||
    !news_date.isSame(post.news_date, 'day');

  const handleStockValueChange = useCallback(
    (stockValue: string) => {
      setPost((prevPost: Post) => {
        return {
          ...prevPost,
          stock_value_change: stockValue,
        };
      });
    },
    [setPost]
  );

  const handleFetchValue = useCallback(
    (stockValue: string) => {
      if (isCachedPostDifferent) {
        handleStockValueChange(stockValue);
      }
    },
    [isCachedPostDifferent, handleStockValueChange]
  );

  const { handleRefresh } = useStockValue({
    ticker: ticker ? ticker.ticker : null,
    date: news_date, // TODO: Revisar cómo funciona con los timezones
    onFetchValue: handleFetchValue,
    onRefresh: handleStockValueChange,
  });

  return (
    <Grid direction="column" alignItems="left" spacing={2}>
      <Grid >
        <StockValueInput
          value={stock_value_change}
          onChange={handleStockValueChange}
          onRefresh={handleRefresh}
        />
      </Grid>
    </Grid>
  );
};

export default PostEditorStockValue;
