import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button/Button";

import { useNavigate } from "react-router-dom";

import {
  ArrowBack as ArrowBackIcon,
  Create as CreateIcon,
} from "@mui/icons-material";
import Box from '@mui/material/Box/Box';

const NavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  height: `34px`,
  width: `34px`,
  border: '1px solid',
  '&:hover': {
    backgroundColor: theme.palette.contrastThreshold,
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  }
}));


export const GoBackBtn = () => {
  const navigate = useNavigate();
  return (
    <NavButton onClick={() => { navigate(`/news`) }}>
      <ArrowBackIcon sx={{ fontSize: 40 }} />
    </NavButton>
  )
}

export const CreateNewPostBtn = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <NavButton onClick={() => { navigate(`/news/post`) }}>
        <CreateIcon sx={{ fontSize: `2rem`, }} />
      </NavButton>
    </Box >
  )
}
