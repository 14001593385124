import React from "react";

import {
  Toolbar,
  AppBar as MUIAppBar,
} from "@mui/material";

interface Props {
  createBtn?: boolean;
  filters?: boolean;
  menu?: React.ReactElement | JSX.Element | JSX.Element[];
};

const AppBar: React.FC<Props> = (props) => {

  return (
    <MUIAppBar >
      {/* <HideOnScroll > */}
      {/* <ReactAppBar ref={appBarRef}> */}
      <Toolbar >
        {props.menu}
      </Toolbar>
      {/* </ReactAppBar> */}
      {/* </HideOnScroll> */}
      {/* <Box component="div"> </Box> */}
    </ MUIAppBar>
  )
}

export default AppBar;
