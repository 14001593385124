import React, { useState } from 'react';
import { Box, Typography, Divider, Tabs, Tab } from '@mui/material';
import EditFontSettings from './EditFontSettings';
import EditFilters from './EditSettingsFilters';

const EditSettings: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Edit Settings
      </Typography>

      <Box marginTop={3}>
        <Typography variant="h5" gutterBottom>
          News Feed
        </Typography>

        {/* Tabs for navigation */}
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="settings tabs">
          <Tab label="Filters" />
          <Tab label="Font Settings" />
        </Tabs>

        <Divider style={{ margin: '20px 0' }} />

        {/* Render the selected tab's content */}
        {tabIndex === 0 && <EditFilters />}
        {tabIndex === 1 && <EditFontSettings />}
      </Box>

    </Box>
  );
};

export default EditSettings;
