import React, { createContext, useContext, useEffect } from "react";

import dayjs from "dayjs";
import _ from "lodash";

import { Post } from "../../../Types/Post";

import { ApolloQueryResult, ObservableQuery, OperationVariables, useQuery } from "@apollo/client";
import { GET_POSTS, GET_POSTS_TOTAL } from "../../../Querys";

import { IndexContext } from "./IndexProvider";
import { DATE_ORDER_BY, Filters, useFilters } from "../Components/Filters/FiltersProvider";
import { FEEDS, useFeedsState } from "./FeedsStateProvider";
import { useAuth0 } from "@auth0/auth0-react";

// Step 1: Create a context

export function postQuerySerializer(filters: Filters): OperationVariables {
  if (filters.orderDatesBy.split('|').length !== 2) throw new Error(`Error splting orderby ${filters.orderDatesBy}`);
  var postArguments: { [key: string]: any } = {
    fromPostDate: filters.fromPostDate?.format('YYYY-MM-DD'),
    untilPostDate: filters.untilPostDate?.add(1, 'day').format('YYYY-MM-DD'),
    offset: 0, limit: 100,
    orderBy: [
      { column: 'aknowledge_delete', order: 'desc' },
      { column: 'rejected', order: 'desc', }
    ]
  };

  if (filters.analyst) {
    postArguments = {
      ...postArguments,
      submitUserId: filters.analyst.id
    }
  }

  if (filters.orderAlphabetically)
    postArguments.orderBy.push({ column: 'news_tickers.ticker', order: filters.orderAlphabetically })

  postArguments.orderBy.push({ column: filters.orderDatesBy.split('|')[0], order: filters.orderDatesBy.split('|')[1] })

  if (filters.searchClause)
    postArguments = {
      ...postArguments,
      searchClauseOnTittle: filters.searchClause,
      searchClauseOnContent: filters.searchClause
    };

  if (filters.ticker)
    postArguments = {
      ...postArguments,
      tickerId: filters.ticker.id,
      ticker: filters.ticker
    }

  return postArguments
};

export function getNewPost(): Post {
  return {
    title: "",
    ticker: null,
    news_date: dayjs(),

    news_ticker_id: null,
    stock_value_change: "($0.00, +0.00%)",
    aknowledge_delete: false,

    active: true,
    archives: [],
    content: "",
    content_type: "html",
  }
};

interface QueryContextValue {
  posts: Post[];
  total: number;
  nextPost: (post?: Post) => void;
  refechCount: typeof ObservableQuery.prototype.refetch;
  totals: { total: number | null, showingTotal: number | null }
}

export var PostsContext = createContext<QueryContextValue>({
  posts: [],
  total: 0,
  nextPost: () => { throw new Error("Trying to call a Inital Func") },
  refechCount: () => Promise.resolve({} as ApolloQueryResult<any>),
  totals: { total: null, showingTotal: null }
});

interface QueryResultProviderProps {
  children: React.ReactNode;
}

// Step 2: Create a context provider component
export const PostsProvider: React.FC<QueryResultProviderProps & { children: React.ReactNode }> = ({ children, }) => {

  // Auth we get the user information for this Screen
  const { user } = useAuth0();
  if (user === undefined || user.sub === undefined) throw new Error("User dont have permissions Screen");
  // var userId = Number(user.sub.split('|')[1]);

  // Index Provider, To init the index.
  const [index, setIndex] = useContext(IndexContext);

  // Get Filters
  const [filters] = useFilters();


  // Get Current Feed and the variables
  const { getFeedVariables, useFeedState } = useFeedsState();
  const feed = useFeedState[0];

  var {
    data: { posts } = { posts: [] },
    loading: loadingPosts,
    refetch
  } = useQuery<{ posts: Post[] }>(GET_POSTS, {
    variables: {
      ...postQuerySerializer(filters),
      ...getFeedVariables()
    },
    onCompleted: ({ posts }) => {
      if (posts.length > 0) {
        const sortedPosts = _.orderBy(posts, ['active', 'rejected'], ['asc', 'desc']);
        const firstPostWithId = sortedPosts.find(post => post.id !== undefined);
        if (firstPostWithId) {
          setIndex(firstPostWithId.id as number); // Asegúrate de que id no sea undefined
        }
      }
    }
  });

  // Getting Posts Total Count.
  const {
    data: { postsCount: { total } } = { postsCount: { total: 0 } },
    refetch: refechCount
  } = useQuery<{ postsCount: { total: number } }>(GET_POSTS_TOTAL, {
    variables: {
      ...postQuerySerializer(filters),
      ...getFeedVariables()
    }
  });

  React.useEffect(() => {
    if (!loadingPosts) {
      (async () => {
        Promise.all([
          refetch({
            ...postQuerySerializer(filters), // TODO: Remove Filter Parameters, let the provider set them
            ...getFeedVariables()
          }),
          refechCount({
            ...postQuerySerializer(filters), // TODO: Remove Filter Parameters, let the provider set them
            ...getFeedVariables()
          })
        ]).then(([{ data: { posts } }]) => {
          if (posts.length > 0) {
            const sortedPosts = _.orderBy(posts, ['active', 'rejected'], ['asc', 'desc']);
            const firstPostWithId = sortedPosts.find(post => post.id !== undefined);
            if (firstPostWithId) {
              setIndex(firstPostWithId.id as number); // Asegúrate de que id no sea undefined
            }
          }
        });
      })();
    }
    return () => {
      console.log('<Unmounting>');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed, filters]);


  useEffect(() => {
    if (posts.length === 0 && index != null) {
      setIndex(null);
    } else if (posts.length > 0 && index == null) {
      const firstPostWithId = posts.find(post => post.id !== undefined);
      if (firstPostWithId !== undefined) {
        setIndex(firstPostWithId.id as number);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  const nextPost = (post?: Post) => {
    const currentId = post ? post.id : index;
    const i = posts.findIndex(p => p.id === currentId);

    if (i > -1) {
      if (posts.length > i + 1) { // We have more to go
        if (posts[i + 1].id !== undefined) {
          setIndex(posts[i + 1].id as number);
        }
      } else { // We are on the last one.
        if (posts.length > 1 && posts[i - 1].id !== undefined) {
          setIndex(posts[i - 1].id as number); // Going Backwards
        }
        // if (posts.length > 1) setIndex(posts[0].id)
      }
    } else { // The index post that we are on isn't in the post we find, reset to the first one or set to null
      if (posts.length > 1 && posts[0].id !== undefined) {
        setIndex(posts[0].id as number);
      } else {
        setIndex(null);
      }
    }
    // if (index == null && posts.length > 1) setIndex(posts[0]);
  };

  // Filtering
  // TODO: For Posts Comming from the feed we need to consult if the new post match the current Feed Filtering.
  //       with this we expect only have on cache posts that match the current Filter.
  // TODO: Move this posible to the FeedsStateContext and just bring a function like: posts = feedFilter(posts);
  switch (feed) {
    case FEEDS.PENDING:
      posts = posts.filter(({ archives, aknowledge_delete, rejected }) => (archives != null && archives.length < 1) || aknowledge_delete || rejected)
      break;
    case FEEDS.DELETED:
      posts = posts.filter(({ active }) => !active)
      break;
    default:
      break;
  }

  // if (feed !== FEEDS.DELETED)
  //   posts = posts.filter((post) => {
  //     return (post.active || (post.aknowledge_delete && post.submit_user_id === userId))
  //   });

  // 8.- Ordering
  //      1.- Posts by current user, deleted waiting for acknowledge the deletion.
  //      2.- Posts by currnet user, rejected.
  //      3.- Posts Feed, base on the current Feed Order.

  if (filters.orderDatesBy === DATE_ORDER_BY.UPDATED_AT_DESC) {
    posts = _.orderBy(posts, ['active', 'rejected', 'updated_at'], ['asc', 'desc', 'desc']);
  }
  else if (filters.orderDatesBy === DATE_ORDER_BY.UPDATED_AT_ASC) {
    posts = _.orderBy(posts, ['active', 'rejected', 'updated_at'], ['asc', 'desc', 'asc']);
  }
  else {
    posts = _.orderBy(posts, ['active', 'rejected'], ['asc', 'desc']);
  }

  return <PostsContext.Provider value={{ posts, refechCount, nextPost, total, totals: { total: 0, showingTotal: 0 } }}> {children} </PostsContext.Provider>;
}

export const usePostsFeed = (): QueryContextValue => {
  const context = useContext(PostsContext);
  if (!context) {
    throw new Error(
      'useQueryResult must be used within a QueryResultProvider'
    );
  }
  return context;
};