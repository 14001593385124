import React, { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Layout from '../Components/Layout/Layout';
import { Box, createFilterOptions, FormControl, styled, Grid2 as Grid } from '@mui/material';
import TickerInputSelectBase from '../Components/PostsFeed/Components/commons/TickerInputSelect';
import { useAuth0 } from '@auth0/auth0-react';
import { Ticker } from '../Types/Ticker';
import { GoBackBtn } from '../Components/Layout/NavButtons';
import { PostEditorProvider, usePostEditor } from '../Components/PostsFeed/Components/PostEditor/PostEditorProvider';
import PostEditorContent from '../Components/PostsFeed/Components/PostEditor/Components/PostEditorContent';
import PostEditorStockValue from '../Components/PostsFeed/Components/PostEditor/Components/PostEditorStockValue';
import PostEditorDatePicker from '../Components/PostsFeed/Components/PostEditor/Components/PostEditorDatePicker';
import PostEditorArchives from '../Components/PostsFeed/Components/PostEditor/Components/PostEditorArchives';
import PostEditorActionButtons from '../Components/PostsFeed/Components/PostEditor/Components/PostEditorActionButtons';
import PostEvents from '../Components/PostsFeed/Components/PostEditor/PostsEvents';

const PostScreen = () => {

  const { id } = useParams<{ id?: string }>();
  const { user } = useAuth0();

  const scrollableRef = useRef<HTMLDivElement | null>(null); // Referencia para el contenedor scrollable

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.focus();
    }
    return () => {
      console.log("Unmounting")
    }
  }, []);

  if (Number.isNaN(id)) return null;
  if (user === undefined || user.sub === undefined) throw new Error("No User");

  var userId = Number(user.sub.split('|')[1]);

  const menu = <Menu />;

  return (
    <PostEditorProvider postId={Number(id)}>
      <Layout menu={menu} sx={{ padding: 2 }}>
        <Box
          ref={scrollableRef}
          tabIndex={0} // Esto permite que el contenedor sea focusable
          sx={{
            outline: 'none', // Oculta las líneas de enfoque
          }}
        >
          <PostEditorContent />

          <Grid container spacing={2}>
            <Grid container size={6}>
              <Grid size={{ md: 6 }}>
                <Grid sx={{ my: 2 }}>
                  <PostEditorStockValue />
                </Grid>
                <Grid sx={{ my: 2 }}>
                  <PostEditorDatePicker />
                </Grid>
                <PostEditorArchives />
              </Grid>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Box>
                <Box sx={{ py: 4 }}>
                  <PostEditorActionButtons userId={userId} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <Box>
                {(id !== undefined) && <PostEvents postId={Number(id)} />}
                <div />
              </Box>
            </Grid>
          </Grid>
        </Box >
      </Layout>
    </PostEditorProvider>
  )
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  color: 'inherit',
  margin: '.3rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

const TickerInputSelect = styled(TickerInputSelectBase)(({ theme }) => ({
  marginLeft: 1,
  minWidth: 200,
  // my: 1,
  color: "white",
  svg: { color: 'white' },
  input: { color: 'white' },
  label: {
    color: 'white'
  },
  "& .MuiInputLabel-root": {
    "&.MuiInputLabel-sizeSmall": {
      fontSize: "1.3rem",
    }
  },
  WebkitTextFillColor: "white !important",  // Corrected camelCase for text-fill-color
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)',
  },
  '.MuiSvgIcon-root': {
    fill: "white !important",
  }
}));

const filterOptions = createFilterOptions<Ticker>({
  matchFrom: 'start',
});

const Menu: React.FC = () => {
  const { draftPost: post, setDraftPost: setPost } = usePostEditor(); // Usamos el contexto para acceder a post y setPost

  return (
    <Box sx={{ display: 'flex', flex: '0 0 100%', flexDirection: 'column', padding: '0 10px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flex: { sx: 1 }, alignItems: 'center' }}>
          <GoBackBtn />
        </Box>
        <Box
          component="div"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', overflowX: 'auto', overflow: 'auto' }}
        >
          <StyledFormControl size="small">
            <TickerInputSelect
              value={post.ticker}
              autoSelect
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              disabled={!post.active}
              filterOptions={filterOptions}
              getOptionLabel={(tickerOption) => tickerOption.ticker || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, ticker) => {
                setPost({
                  ...post,
                  news_ticker_id: ticker ? ticker.id : null,
                  ticker: ticker
                });
              }}
              size="small"
            />
          </StyledFormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default PostScreen;