import React from 'react';
import ArchivesInput from '../../commons/ArchivesInput';
import { usePostEditor } from '../PostEditorProvider';

const PostEditorArchives: React.FC = () => {
  const { draftPost, setDraftPost: setdraftPost } = usePostEditor(); // Usamos el contexto para obtener los valores

  return (
    <>
      {draftPost.active && draftPost.archives && (
        <ArchivesInput
          value={draftPost.archives}
          onChange={(archives) => {
            console.log('Setting Post Archives', archives);
            setdraftPost((prevPost) => ({ ...prevPost, archives }));
          }}
        />
      )}
    </>
  );
};

export default PostEditorArchives;
