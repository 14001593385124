import React, { createContext, useContext, ReactNode, useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Drawer, GlobalStyles, Toolbar, SxProps, Theme, IconButton } from "@mui/material";
import AppBar from "./AppBar";
import MobileMenu from "./MobileMenu";
import { useFilters } from "../PostsFeed/Components/Filters/FiltersProvider";
import { MenuIcon } from "lucide-react";


// Definimos el tipo de estado que va a manejar el contexto
interface LayoutState {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSearchByClouseOpen: boolean;
  setIsSearchByClouseOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


// Inicializamos el contexto con un valor indefinido para capturar errores
const LayoutContext = createContext<LayoutState | undefined>(undefined);

// Hook personalizado para acceder al contexto
export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) throw new Error("useLayout debe ser usado dentro del LayoutContext.Provider");
  return context;
};

interface Props {
  children: ReactNode; // Cambiamos `React.ReactElement` a `ReactNode` para permitir cualquier tipo de nodo React.
  menu?: React.ReactElement | JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>; // Tipado con `Theme` para mejorar el soporte del tema.
}


const Layout: React.FC<Props> = ({ children, menu = <BaseMenu></BaseMenu>, sx }) => {
  // Estado del drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Using the App Filters.
  const [filters] = useFilters();

  // Estado de `isSearchByClouseOpen`
  const [isSearchByClouseOpen, setIsSearchByClouseOpen] = useState(filters.searchClause != null);


  return (
    <LayoutContext.Provider value={{ isDrawerOpen, setIsDrawerOpen, isSearchByClouseOpen, setIsSearchByClouseOpen }}>
      <CssBaseline />
      <GlobalStyles styles={{ p: { margin: 0 } }} />

      {/* Drawer Component */}
      <CustomDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={() => setIsDrawerOpen(!isDrawerOpen)} />

      {/* Barra de navegación superior */}
      <AppBar menu={menu} />

      {/* Contenedor principal */}
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {/* Espacio adicional para ajustar la posición del contenido */}
        <Toolbar sx={{ visibility: 'hidden', pointerEvents: 'none' }}>
          {menu}
        </Toolbar>

        {/* Contenedor de contenido principal */}
        <Box component="main" sx={{ flex: '1 1 auto', overflow: "auto", ...sx }} >
          {children}
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
};
// Componente separado para el Drawer
const CustomDrawer: React.FC<{ isDrawerOpen: boolean; toggleDrawer: () => void }> = ({ isDrawerOpen, toggleDrawer }) => (
  <Drawer
    variant="temporary"
    open={isDrawerOpen}
    onClose={toggleDrawer}
    ModalProps={{ keepMounted: true }} // Mejor rendimiento en dispositivos móviles
    sx={{ '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}
  >
    <MobileMenu onClick={toggleDrawer} />
  </Drawer>
);

const BaseMenu: React.FC = () => {
  const { setIsDrawerOpen } = useLayout();

  return (
    <Box >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={() => {
            setIsDrawerOpen(isOpen => !isOpen);
          }} >
            <MenuIcon />
          </IconButton >
        </Box>
      </Box>
    </Box>
  )
}


export default Layout;
