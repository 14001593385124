import React from 'react'

import { Button } from '@mui/material';
import { SortByAlpha } from '@mui/icons-material';

import { SORT_ORDER, useFilters } from './FiltersProvider';

type Props = {}

const TickerSortOrderToggleBtn = (props: Props) => {
  const [filters, setFilters] = useFilters();

  return (
    <Button

      onClick={(event) => {
        if (filters.orderAlphabetically === null)
          setFilters(f => ({ ...f, orderAlphabetically: SORT_ORDER.ASC }))
        else if (filters.orderAlphabetically === SORT_ORDER.ASC)
          setFilters(f => ({ ...f, orderAlphabetically: SORT_ORDER.DESC }))
        else
          setFilters(f => ({ ...f, orderAlphabetically: null }))
      }}
      variant="outlined"
      size='large'
      sx={{
        color: 'white',
        borderColor: 'rgba(228, 219, 233, 0.25)', // Establece el color de borde personalizado
        // fontSize: '18px', // Aumenta el tamaño de la fuente
        padding: '11.5px 24px', // Ajusta el padding para hacer el botón más alto y ancho
        '&:hover': {
          borderColor: 'rgba(228, 219, 233, 0.25)', // Mantiene el color del borde en hover
          backgroundColor: 'rgba(255, 255, 255, 0.1)', // Opcional: añade un fondo semitransparente en hover
        }
      }}
      startIcon={<SortByAlpha />}>
      {
        (filters.orderAlphabetically !== null) ?
          filters.orderAlphabetically
          : 'none'
      }
    </Button>
  )
}

export default TickerSortOrderToggleBtn;