// SettingsScreen.tsx
import React from 'react';
import { Container, Typography } from '@mui/material';
import Layout from '../Components/Layout/Index';
import EditSettings from '../Components/Settings/EditSettings';

const SettingsScreen: React.FC = () => {
  return (
    <Layout sx={{ padding: 2 }}>
      {/* <Container>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
      </Container> */}
      <EditSettings />


    </Layout>
  );
};


export default SettingsScreen;
