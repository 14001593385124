import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { useColorScheme } from '@mui/material/styles';

// Component to switch themes using a ButtonGroup instead of RadioGroup
const ThemeToggleButtonGroup: React.FC = () => {
  const { mode, setMode } = useColorScheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ButtonGroup variant="contained" aria-label="theme toggle button group">
        <Button
          onClick={() => setMode('system')}
          color={mode === 'system' ? 'primary' : 'inherit'}
        >
          System
        </Button>
        <Button
          onClick={() => setMode('light')}
          color={mode === 'light' ? 'primary' : 'inherit'}
        >
          Light
        </Button>
        <Button
          onClick={() => setMode('dark')}
          color={mode === 'dark' ? 'primary' : 'inherit'}

        >
          Dark
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ThemeToggleButtonGroup;
