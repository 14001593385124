import React from 'react';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { Box } from '@mui/material';
import { usePostEditor } from '../PostEditorProvider';
import { Dayjs } from 'dayjs';

const PostEditorDatePicker: React.FC = () => {
  const { draftPost: post, setDraftPost: setPost } = usePostEditor();

  const handleDateChange = (news_date: Dayjs | null) => {
    if (!news_date) return;
    console.log("Setting post date");
    setPost((prevPost) => ({ ...prevPost, news_date }));
  };

  return (
    <DesktopDateTimePicker
      label="News Original Date"
      value={post.news_date}
      onChange={handleDateChange}
      disabled={!post.active}
      slotProps={{
        textField: {
          InputProps: {
            sx: { fontSize: '20px', fontWeight: 'bold' },
          },
          size: 'small',
          variant: 'outlined', // Cambié a outlined para un aspecto más moderno
        },
      }}
    />
  );
};

export default PostEditorDatePicker;
