import React from "react";
import { useQuery } from "@apollo/client";
import { MenuItem, SelectProps, Select, SelectChangeEvent, FormControl, InputLabel, Skeleton, FormControlProps } from "@mui/material";
import { GET_ARCHIVES } from "../../../../../Querys";
import { Archive } from "../../../../../Types/Archive";
import { BASE_STATUSES, FEEDS } from "../../../Providers/FeedsStateProvider";
import { styled } from "@mui/material/styles";

// Custom styling for the FormControl
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // fontsize: theme.typography.fontSize,
  color: 'inherit',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));


// Type definitions using discriminated unions for mutual exclusivity between `label` and `CustomInputLabel`
interface WithLabel {
  label: string; // Requires `label` prop
  CustomInputLabel?: never; // `CustomInputLabel` should not be present
}

interface WithCustomInputLabel {
  label?: never; // `label` should not be present
  CustomInputLabel: React.ReactNode; // Requires `CustomInputLabel` prop
}

type InputSelectPostsStatusProps = (WithLabel | WithCustomInputLabel) & {
  feedsState: FEEDS | number;
  onStatusChange: (event: SelectChangeEvent<unknown>) => void;
  CustomSelectComponent?: React.ComponentType<SelectProps>; // Optional custom Select component
  StyledFormControlProps?: FormControlProps; // New prop for additional FormControl properties
};

const InputSelectPostsStatus: React.FC<InputSelectPostsStatusProps> = React.memo(
  ({ feedsState, onStatusChange, label, CustomSelectComponent, CustomInputLabel, StyledFormControlProps }) => {
    const { loading, error, data } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES);

    // Display a loading skeleton if data is still being fetched
    if (loading) return <Skeleton variant="rectangular" width="100%" height={40} />;
    if (error) return <div>Error loading archives</div>;

    // Use either the custom Select component or the default Select
    const SelectComponent = CustomSelectComponent || Select;

    // Determine the labelId to be used in the Select
    let labelId = "status-select-2"; // Fallback value for `labelId`

    // If CustomInputLabel is provided, extract its `id` prop if defined
    if (React.isValidElement(CustomInputLabel) && CustomInputLabel.props.id) {
      labelId = CustomInputLabel.props.id;
      label = CustomInputLabel.props.children;
    }

    return (
      // Spread the `StyledFormControlProps` onto the StyledFormControl component
      <StyledFormControl  {...StyledFormControlProps} >
        {/* Use the provided `CustomInputLabel` or render a default InputLabel based on `label` prop */}
        {CustomInputLabel ? (
          CustomInputLabel
        ) : (
          <InputLabel id={labelId}>
            {label}
          </InputLabel>
        )}

        <SelectComponent
          labelId={labelId} // Use the labelId from InputLabel
          id="filter-status-select"
          value={feedsState}
          onChange={onStatusChange} // Use the provided `onStatusChange` prop
          {...(label && { label })} // Include `label` prop only if `label` is present
        >
          {/* Render the BASE_STATUSES options */}
          {BASE_STATUSES.map((status, i) => (
            <MenuItem key={i} value={status.value}>
              {status.label}
            </MenuItem>
          ))}

          {/* Render the options from the fetched archives data */}
          {data?.archives?.map((archive, i) => (
            <MenuItem key={i} value={archive.id}>
              {archive.description}
            </MenuItem>
          ))}
        </SelectComponent>
      </StyledFormControl>
    );
  }
);

export default InputSelectPostsStatus;
