import React from 'react';
import type { ComponentProps } from "react";

// Data Handling
import { useQuery } from '@apollo/client';

// MUI
import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { Ticker } from '../../../../Types/Ticker';
import { GET_TICKERS } from '../../../../Querys';
import _ from 'lodash';

const filterOptions = createFilterOptions<Ticker>({
    matchFrom: 'start',
});

const TickerInputSelect: React.FC<Omit<ComponentProps<typeof Autocomplete<Ticker>>, 'options' | 'renderInput'>> = (props) => {

    const { loading, error, data: { tickers } = { tickers: [] } } = useQuery<{ tickers: Ticker[] }>(GET_TICKERS, {});

    if (loading) return null;
    if (error) return <>"An error has occurred: " + ${error.message}</>;

    return <Autocomplete
        id="ticker-autocompleate"
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
            }
        }}
        filterOptions={filterOptions}
        autoHighlight
        blurOnSelect
        getOptionLabel={(tickerOption) => tickerOption.ticker || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={_.orderBy(tickers, ['ticker'], ['asc']).map(t => ({ ...t, __typename: undefined }))}
        {...props}
        renderInput={(params) => <TextField {...params} label="Ticker" />}
    />
};
export default TickerInputSelect;