import React from 'react';
import { Box, Typography, Grid2 as Grid, SelectChangeEvent } from '@mui/material';
import InputSelectPostsStatus from '../PostsFeed/Components/commons/Inputs/InputSelectPostsStatus';
import InputSelectOrderBy from '../PostsFeed/Components/commons/Inputs/InputSelectOrderBy';
import { DATE_ORDER_BY } from '../PostsFeed/Components/Filters/FiltersProvider';
import { FEEDS } from '../PostsFeed/Providers/FeedsStateProvider';
import { useSettings } from './SettingsContext';

const EditFilters: React.FC = () => {
  const { settings, updateSettings } = useSettings();

  const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
    const newStatus = event.target.value as FEEDS;
    updateSettings({ filtersDefaultStatus: newStatus });
  };

  const handleOrderByChange = (event: SelectChangeEvent<unknown>) => {
    const newOrder = event.target.value as DATE_ORDER_BY;
    updateSettings({ filtersDefaultDateOrder: newOrder });
  };

  return (
    <Box marginLeft={2}>
      <Typography variant="subtitle1" gutterBottom>
        Filters
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <InputSelectPostsStatus
            StyledFormControlProps={{ fullWidth: true }}
            label="Status"
            feedsState={settings.filtersDefaultStatus || FEEDS.PENDING}
            onStatusChange={handleStatusChange}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <InputSelectOrderBy
            StyledFormControlProps={{ fullWidth: true }}
            label="Order by date"
            orderDatesBy={settings.filtersDefaultDateOrder || DATE_ORDER_BY.UPDATED_AT_DESC}
            onOrderByChange={handleOrderByChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditFilters;
