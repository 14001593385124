import React, { useCallback } from "react";
import { useFeedsState } from "../../Providers/FeedsStateProvider";
import InputSelectStatus from "../commons/Inputs/InputSelectPostsStatus";

import { SelectChangeEvent, InputLabel } from "@mui/material";
import { FEEDS } from "../../Providers/FeedsStateProvider";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import InputSelectOrderBy from "../commons/Inputs/InputSelectOrderBy";
import { useFilters } from "./FiltersProvider";

const CustomStyledSelect = styled(Select)(({ theme }) => ({
    color: "white",
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(228, 219, 233, 0.25)",
    },
    ".MuiSvgIcon-root": {
        fill: "white !important",
    },
}));


export const FilterInputSelectStatus: React.FC = () => {
    const { useFeedState } = useFeedsState();
    const [feedsState, setFeedsState] = useFeedState;

    // Handler for changes in the Select component
    const handleStatusChange = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const newValue = event.target.value as FEEDS | number;
            setFeedsState(newValue);
            console.log(`Status changed to: ${newValue}`);
        },
        [setFeedsState]
    );

    return (
        <InputSelectStatus
            feedsState={feedsState}
            onStatusChange={handleStatusChange}
            CustomSelectComponent={CustomStyledSelect}
            StyledFormControlProps={{
                size: "small",
                sx: { width: "100%", color: "inherit" }
            }}
            // Provide only one of the props: `label` or `CustomInputLabel`
            CustomInputLabel={
                <InputLabel id="filter-status" sx={{ color: "white" }}>
                    Status
                </InputLabel >
            }
        />
    );
};

export const FilterInputOrderBy: React.FC = () => {
    // Retrieve the current filters and the function to update them from the context
    const [filters, setFilters] = useFilters();


    return (
        <>
            {/* Using the context state (`filters`) and passing a custom InputLabel and Select component */}
            <InputSelectOrderBy
                orderDatesBy={filters.orderDatesBy} // Use the orderDatesBy value from the context
                onOrderByChange={(event) => {
                    // Update the filters context when the order changes
                    setFilters((prevFilters: any) => ({
                        ...prevFilters,
                        orderDatesBy: event.target.value, // Update the `orderDatesBy` value
                    }));
                }}
                StyledFormControlProps={{
                    size: "small",
                    sx: { m: 0.5 }
                }}
                CustomInputLabel={
                    <InputLabel id="filter-order-by" sx={{ color: "white" }}>
                        Order By
                    </InputLabel>
                }
                CustomSelectComponent={CustomStyledSelect} // Use a custom Select component for styling
            />
        </>
    );
};