import React, { createContext, useMemo, useContext } from "react";
import { useQuery } from "@apollo/client";
import { Archive } from "../../../Types/Archive";
import { GET_ARCHIVES } from "../../../Querys";
import { useAuth0 } from "@auth0/auth0-react";
import { useSettings } from "../../Settings/SettingsContext";

// Enum for feed states
export enum FEEDS {
  ALL = "all",
  PENDING = "pending", // Ticker of this user that haven't been archived.
  ARCHIVED = "archived",
  DELETED = "deleted",
}

interface Status {
  value: FEEDS;
  label: string;
}

// Base statuses with corresponding labels
export const BASE_STATUSES: Status[] = [
  { value: FEEDS.ALL, label: "All" },
  { value: FEEDS.PENDING, label: "Pending" },
  { value: FEEDS.ARCHIVED, label: "Archived" },
  { value: FEEDS.DELETED, label: "Deleted" },
];

// Define return type for getFeedVariables
interface FeedVariables {
  archives?: number[];
  active?: boolean;
  tickersUserId?: number;
  aknowledgeDelete?: boolean;
}

type FeedStateContext = {
  useFeedState: [FEEDS | number, React.Dispatch<React.SetStateAction<FEEDS | number>>];
  getFeedVariables: () => FeedVariables;
};

// Context for feeds state
export const FeedsStateContext = createContext<FeedStateContext | undefined>(undefined);

// Step 2: Create a context provider component
const FeedsStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth0();

  // Ensure user is defined and has permissions
  if (!user?.sub) {
    throw new Error("User does not have permissions or user.sub is undefined.");
  }

  // Extract the userId from Auth0's user sub
  const userId = useMemo(() => {
    if (!user?.sub) {
      throw new Error("User sub is undefined, cannot extract userId.");
    }

    const parts = user.sub.split("|");

    if (parts.length < 2) {
      throw new Error("Unexpected user sub format, cannot extract userId.");
    }

    return Number(parts[1]);
  }, [user?.sub]);

  // State for feed, initialized to PENDING
  const { settings } = useSettings();
  const useFeedState = React.useState<FEEDS | number>(settings.filtersDefaultStatus);

  // Fetch archives with Apollo useQuery
  const { data: { archives } = { archives: [] } } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES, {});

  const feed = useFeedState[0];

  // Memoized function to generate the variables for the feed
  const getFeedVariables = useMemo(() => {
    return (): FeedVariables => {
      switch (feed) {
        case FEEDS.PENDING:
          return { archives: [], active: true, tickersUserId: userId };
        case FEEDS.ARCHIVED:
          return { active: true, archives: archives.map((a) => a.id) };
        case FEEDS.DELETED:
          return { active: false, aknowledgeDelete: false };
        case FEEDS.ALL:
          return { active: true };
        default:
          if (!Number.isNaN(feed)) {
            return { archives: [feed] }; // If feed is an archive number
          }
          throw new Error(`Unhandled feed type: ${feed}`);
      }
    };
  }, [feed, archives, userId]);

  return (
    <FeedsStateContext.Provider value={{ useFeedState, getFeedVariables }}>
      {children}
    </FeedsStateContext.Provider>
  );
};

export default FeedsStateProvider;

// Hook for accessing the feeds state context safely
export const useFeedsState = (): FeedStateContext => {
  const context = useContext(FeedsStateContext);
  if (!context) {
    throw new Error("useFeedsState must be used within a FeedsStateProvider");
  }
  return context;
};