import React from "react";

import DOMPurify from "dompurify";
import parse from "html-react-parser";
import dayjs from "dayjs";
import { Box, styled, Typography } from "@mui/material";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import { Post } from "../../../../Types/Post";
import ArchiveIcon from '@mui/icons-material/Archive';
import ErrorIcon from '@mui/icons-material/Error';
import { PreviewDateAttr } from "../Filters/FiltersProvider";
import FolderDeleteIcon from '@mui/icons-material/FolderDelete'; // Importar FolderDeleteIcon

const StyledRowPostPreview = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isNew" && prop !== "active",
})<{
  selected: boolean;
  isNew: boolean;
  active: boolean;
}>(({ selected, isNew, active, theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: 5,
  paddingBottom: 5,
  backgroundColor: selected
    ? theme.palette.mode === "dark"
      ? "#37474F" // Dark mode selected background color
      : "#B2DFDB" // Light mode selected background color
    : isNew
      ? theme.palette.mode === "dark"
        ? "#455A64" // Dark mode new background color
        : "#CCFFD0" // Light mode new background color
      : undefined,
  cursor: "pointer",
  ...(active === false && {
    textDecoration: "line-through",
    backgroundColor: "#808080",
    opacity: 0.5,
  }),
}));

type PostRowProps = {
  post: Post;
  dateField: PreviewDateAttr;
  selected: boolean;
  onClick: (post: Post) => void;
};

// Nuevo componente PostRow que reemplaza a renderRow
export const PostRow: React.FC<PostRowProps> = ({ post, dateField, onClick, selected }) => {
  const cleanHTML = DOMPurify.sanitize(post.title, {
    USE_PROFILES: { html: true },
  });


  const getDateTime = (post: Post, dateFilter: PreviewDateAttr = 'updated_at') => {
    const date = post[dateFilter];
    return (
      <div>
        <div style={{ color: "rgb(21, 101, 192)", fontSize: "14px", lineHeight: "10px" }}>
          {date ? dayjs(date).format('MM/DD/YYYY') : ""}
        </div>
        <div style={{ color: "rgb(21, 101, 192)", fontSize: "14px", lineHeight: "20px" }}>
          {date ? dayjs(date).format("HH:mm") : ""}
        </div>
      </div>
    );
  };

  return (
    <StyledRowPostPreview
      selected={selected}
      isNew={!!post.isNew}
      onClick={() => onClick(post)}
      active={post.active}
    >

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          fontWeight: "bold",
          textAlign: "center",

          paddingRight: "5px",
          paddingLeft: "5px"
        }}
      >
        {post.rejected && <ErrorIcon sx={{ color: `#F44336`, fontSize: 'small' }} />}
        <Typography variant="ticker">
          {post.ticker ? post.ticker.description : ""}
        </Typography>
        {getDateTime(post, dateField)}
      </Box>

      <Box sx={{ display: "flex", flex: "1", height: "100%", flexDirection: "column" }}>
        <Box
          sx={{
            flexGrow: 1,
            lineHeight: '1.3em',
            marginRight: '10px',
            display: "flex",
            alignItems: "center",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 'clamp(14px, 2vw + 1rem, 19px)',
          }}
        >
          <Typography variant="postTitle">
            {parse(cleanHTML)}
          </Typography>
        </Box>
        <Box sx={{ color: '#2196F3' }}>
          {(post.stock_value_change && post.stock_value_change !== "($0.00, 0.00%)") ? post.stock_value_change : ""}
        </Box>
      </Box>

      <Box>
        <StatusIcon post={post} />
      </Box>
    </StyledRowPostPreview>
  );
};

const StatusIcon: React.FC<{ post: Post }> = ({ post }) => {
  if (!post.active) {
    return <FolderDeleteIcon style={{ color: `#000000` }} fontSize="medium" />;
  }
  if (post.rejected) {
    return <ErrorIcon style={{ color: `#F44336` }} fontSize="medium" />;
  }
  if (post.archives && post.archives.length > 0) {
    return <ArchiveIcon style={{ color: `#9e9e9e` }} fontSize="medium" />;
  }
  return <MarkunreadMailboxIcon style={{ color: `#4CAF50` }} fontSize="medium" />;
};
