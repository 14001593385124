import { Box, Typography } from '@mui/material';
import FroalaEditor from '../../commons/FroalaEditor';
import { usePostEditor } from '../PostEditorProvider';

const PostEditorContent: React.FC = () => {
  const { draftPost: post, setDraftPost: setPost } = usePostEditor(); // Usamos el contexto para acceder a post y setPost

  return (
    <>
      <Box sx={{ lineHeight: 1.5, marginRight: '10px', paddingY: 1 }}>
        <Typography variant='postTitle'>
          <FroalaEditor
            placeholderText='Title'
            value={post.title}
            onChange={(event: string) => {
              if (event !== undefined && post.active) {
                setPost(post => ({ ...post, title: event }));
              }
            }}
          />
        </Typography>
      </Box>
      <Box sx={{ lineHeight: 1.5, marginTop: 1, borderBottom: 1, borderTop: 1, paddingY: 1 }}>
        <Typography variant='postContent'
          sx={{
            '& p:not(:last-child)': {
              paddingBottom: '.7em',
            }
          }}
        >
          <FroalaEditor
            placeholderText='Body'
            value={post.content}
            onChange={(event: string) => {
              if (event != null && post.active) {
                setPost(post => ({ ...post, content: event }));
              }
            }}
          />
        </Typography>
      </Box >
    </>
  );
};

export default PostEditorContent;
