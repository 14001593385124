import React from "react";

import { useQuery, useSubscription } from "@apollo/client";

import { GET_POSTS, GET_POSTS_TOTAL, POST_SUBSCRIPTION } from "../../../Querys";
import { Post } from "../../../Types/Post";
import { useFeedsState } from "./FeedsStateProvider";
import { useFilters } from "../Components/Filters/FiltersProvider";
import { postQuerySerializer } from "./PostsProvider";

export const PostsFeedSubProvider: React.FC<{ children: React.ReactNode }> = ({ children, }) => {

  const { fetchMore } = useQuery<{ posts: Post[] }>(GET_POSTS, { skip: true, });

  const [filters] = useFilters();

  const { getFeedVariables } = useFeedsState();

  // Getting Posts Data.
  const { refetch: refechCount }
    = useQuery<{ postsCount: { total: number } }>(GET_POSTS_TOTAL, {
      variables: {
        ...postQuerySerializer(filters),
        ...getFeedVariables()
      }
    });

  useSubscription<{ subPost: Post }>(POST_SUBSCRIPTION, {
    onData: ({ client, data: { data } }) => {
      console.log("Sub recived: ", data);
      if (data === undefined) return;

      fetchMore({
        variables: {
          id: data.subPost.id,
          ...postQuerySerializer(filters),
          ...getFeedVariables()
        },
        updateQuery: (previousQueryResult, { fetchMoreResult }) => {

          if (fetchMoreResult.posts.length !== 1) return previousQueryResult;
          var posts: Post[] = [...previousQueryResult.posts];

          var post: Post = {
            ...fetchMoreResult.posts[0],
            isNew: true
          }

          const subPostInPostsIndex = posts.findIndex(p => p.id === post.id);

          if (subPostInPostsIndex > -1) { // Update existing post
            posts[subPostInPostsIndex] = post;
          } else { // Adding new Post
            var insertPosition = (post.active && !post.aknowledge_delete && !post.rejected)
              ? posts.findIndex(p => p.active && !p.aknowledge_delete && !p.rejected)
              : 0;
            posts.splice(insertPosition, 0, post);
          };

          // posts = [post, ...previousQueryResult.posts.filter(p => p.id !== post.id)];

          let result = { posts }
          // var result = Object.assign({}, previousQueryResult, { posts });
          return result;
        },
      }) // Variables base on current Feed or Filter
        .then(({ data }) => {
          console.log(data)
          // var post: Post = {
          //   ...data.post,
          //   isNew: true
          // };

          // var { posts } = client.readQuery<{ posts: Post[] }>({ query: GET_POSTS }) || { posts: [] };

          // const postinPosts = posts.some(p => p.id === post.id);
          // if (postinPosts) {
          //   posts = posts.filter(p => p.id !== post.id);
          // };

          // // Check if its deleted.
          // // Append Base on Current Order.
          // posts = [post, ...posts];

          // client.writeQuery({
          //   query: GET_POSTS,
          //   data: {
          //     posts
          //   }
          // });

          //TODO: Find a better way to do this
          refechCount({
            ...postQuerySerializer(filters),
            ...getFeedVariables()
          });
        });
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return <>{children}</>;
};