import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { ARCHIVE_POST, CREATE_NEW_POST, DELETE_POST, GET_ARCHIVES, GET_POST_BY_ID, UPDATE_POST } from '../../../../Querys'; // Tus queries/mutations
import { useNavigate } from 'react-router-dom';
import { Post } from '../../../../Types/Post';
import { getNewPost, PostsContext } from '../../Providers/PostsProvider';
import { Archive } from '../../../../Types/Archive';
import _ from 'lodash';

const ENABLE_LOGS = false;

export enum EDITOR_ACTIONS {
	CREATE = 'submit',
	MODIFY = 'modify',
	DELETE = 'delete',

	ARCHIVE = 'archive',
	UNARCHIVE = 'unarchive',
	UPDATE_ARCHIVES = 'update archives',

	REJECT = 'reject',
	FIX = 'fix',

	AKNOWLEDGE_DELETE = 'aknowledge delete'
};

interface PostEditorContextType {
	draftPost: Post;
	post: Post | undefined; //serverPost
	setDraftPost: React.Dispatch<React.SetStateAction<Post>>;

	handleCreateNewPost: () => Promise<{ createPost: Post } | null | undefined>; // Ya actualizado previamente
	handleUpdatePost: (rejected?: boolean) => Promise<FetchResult<{ post: Post }>>; // Tipo actualizado para handleUpdatePost
	handleDeletePost: () => Promise<void>;
	handleRejectPost: () => Promise<void>;
	handleAcknowledgeDelete: () => Promise<void>;
	handleUpdateArchives: (id: number) => Promise<void>;

	mainBtnRef: React.RefObject<HTMLButtonElement>;
	actions: {
		actions: EDITOR_ACTIONS[];
		setActions: React.Dispatch<React.SetStateAction<EDITOR_ACTIONS[]>>;
	};
}

export const PostEditorContext = createContext<PostEditorContextType | undefined>(undefined);

export const usePostEditor = () => {
	const context = useContext(PostEditorContext);
	if (!context) {
		throw new Error('usePostEditor must be used within a PostEditorProvider');
	}
	return context;
};


interface PostEditorProviderProps {
	children: React.ReactNode;
	postId: number | undefined;
}

export const PostEditorProvider: React.FC<PostEditorProviderProps> = ({ children, postId }) => {
	const navigate = useNavigate();
	const mainBtnRef = useRef<HTMLButtonElement>(null);

	var [draftPost, setDraftPost] = React.useState<Post>(getNewPost());

	const { data: { archives } = { archives: [] } } = useQuery<{ archives: Archive[] }>(GET_ARCHIVES, {});
	const { data: { post } = { post: undefined } } = useQuery<{ post: Post }>(GET_POST_BY_ID, {
		skip: postId == null,
		variables: { id: postId },
		onCompleted: ({ post }) => {
			setDraftPost({
				...post,
				archives: (post.archives && post.archives.length < 1 && archives.length > 0) ? [archives[0].id] : post.archives,
			});
		},
	});

	const actions = usePostEditorActions(draftPost, post);

	const [addPost] = useMutation<{ createPost: Post }>(CREATE_NEW_POST);
	const [updatePost] = useMutation<{ post: Post }>(UPDATE_POST);
	const [deletePost] = useMutation<{ post: Post }>(DELETE_POST);

	const [archivePost] = useMutation<{ archivePost: number[] }>(ARCHIVE_POST, {
		optimisticResponse: {
			archivePost: draftPost.archives || [], // Esto simula la respuesta de la mutación
		},
		update: (cache, { data }) => {
			if (!data || !data.archivePost) return;
			cache.modify({
				id: cache.identify({ __typename: "Post", id: draftPost.id }),
				fields: {
					archives(existingArchives = []) {
						return data.archivePost || existingArchives;
					},
				},
			});
		},
	});

	useEffect(() => {
		if (mainBtnRef && mainBtnRef.current) {
			mainBtnRef.current.focus();
		}
	}, [mainBtnRef]); // Solo se ejecuta cuando `mainBtnRef` cambia

	// Keyboard Handling
	useEffect(() => {
		const handleKeyDownOnPost = async (event: KeyboardEvent) => {
			if (document.activeElement && document.activeElement.classList.contains('fr-view')) return;

			let key = event.key;

			if (key === 'Enter') {
				if (ENABLE_LOGS)
					console.log('enter');
				event.preventDefault();
				event.stopPropagation();

				if (mainBtnRef.current) {
					mainBtnRef.current.click();
				}
			}

		};

		document.addEventListener('keydown', handleKeyDownOnPost);
		return () => {
			console.log('unmounting');
			document.removeEventListener('keydown', handleKeyDownOnPost);
		};
	}, []);

	const handleCreateNewPost = useCallback(async () => {
		console.log(getNewPost().stock_value_change)
		console.log(draftPost.stock_value_change)
		try {
			const { data } = await addPost({
				variables: {
					post: {
						news_ticker_id: draftPost.news_ticker_id,
						news_date: draftPost.news_date.toISOString(),

						title: draftPost.title,
						content: draftPost.content,
						content_type: draftPost.content_type,

						stock_value_change: (draftPost.stock_value_change === getNewPost().stock_value_change || draftPost.stock_value_change === "($0.00, 0.00%)") ? null : draftPost.stock_value_change,
					},
				},
			});
			return data;
		} catch (error) {
			console.error('Error creating post:', error);
		}
	}, [addPost, draftPost]);

	// Get Filters
	const { refechCount } = useContext(PostsContext);
	const handleUpdatePost = async (rejected: Boolean = false) => {
		if (!('id' in draftPost)) throw new Error("Trying to update a post wiht no existing Id");
		return await updatePost({
			variables: {
				post: {
					id: draftPost.id,
					news_ticker_id: draftPost.news_ticker_id,
					news_date: draftPost.news_date.toISOString(),

					title: draftPost.title,
					content: draftPost.content,
					content_type: draftPost.content_type,

					stock_value_change: (draftPost.stock_value_change === getNewPost().stock_value_change) ? null : draftPost.stock_value_change,
					rejected
				}
			}
		});
	};

	const handleRejectPost = async () => {
		if ('id' in draftPost) {
			await handleUpdatePost(true);
			navigate('/');
		}
	};

	const handleDeletePost = async () => {
		const confirm = window.confirm('Are you sure you wish to delete this post?');
		if ('id' in draftPost && confirm) await deletePost({ variables: { id: draftPost.id } });
		navigate('/');
	};

	const handleAcknowledgeDelete = async () => {
		if ('id' in draftPost) {
			await updatePost({
				variables: {
					post: {
						id: draftPost.id,
						aknowledge_delete: false,
					},
				},
			});
			navigate('/');
		}
	};

	const handleUpdateArchives = async (id: number) => {
		await archivePost({
			variables: { id, archives: draftPost.archives },
			refetchQueries: [{ query: GET_POST_BY_ID, variables: { id: draftPost.id } }],
		});
	};

	return (
		<PostEditorContext.Provider
			value={{
				post,
				draftPost,
				setDraftPost,
				handleCreateNewPost,
				handleUpdatePost,
				handleDeletePost,
				handleRejectPost,
				handleAcknowledgeDelete,
				handleUpdateArchives,
				mainBtnRef,
				actions,
			}}
		>
			{children}
		</PostEditorContext.Provider>
	);
};

export const usePostEditorActions = (draftPost: Post, post: Post | undefined) => {
	const [actions, setActions] = useState<EDITOR_ACTIONS[]>([]);

	useEffect(() => {
		function processActions() {
			let actions: EDITOR_ACTIONS[] = [];
			if (ENABLE_LOGS) {
				console.log('Processing actions for post:', post);
				console.log('Saved post state:', post);
			}

			if (post) {
				const areTitleChanges = draftPost.title !== post.title;
				const areContentChanges = draftPost.content !== post.content;
				const valueChange = draftPost.stock_value_change !== post.stock_value_change;
				const newsDateChange = !draftPost.news_date.isSame(post.news_date, 'day');

				if (ENABLE_LOGS) {
					console.log('Title changes:', areTitleChanges);
					console.log('Content changes:', areContentChanges);
					console.log('Stock value change:', valueChange);
					console.log('News date change:', newsDateChange);
				}

				if (areTitleChanges || areContentChanges || newsDateChange || valueChange) {
					actions.push(EDITOR_ACTIONS.MODIFY);
					console.log('Action added: MODIFY');
					if (post.rejected) {
						actions.push(EDITOR_ACTIONS.FIX);
						if (ENABLE_LOGS)
							console.log('Action added: FIX');
					}
				}
			} else if (!post) {
				actions.push(EDITOR_ACTIONS.CREATE);
				if (ENABLE_LOGS)
					console.log('Action added: CREATE');
			}

			// Archive-related logic
			if (post && post.archives && draftPost.archives) {
				if (!_.isEqual([...draftPost.archives].sort(), [...post.archives].sort())) {
					if (post.archives.length === 0 && draftPost.archives.length > 0) {
						actions.push(EDITOR_ACTIONS.ARCHIVE);
						if (ENABLE_LOGS)
							console.log('Action added: ARCHIVE');
					} else if (post.archives.length > 0 && draftPost.archives.length === 0) {
						actions.push(EDITOR_ACTIONS.UNARCHIVE);
						if (ENABLE_LOGS)
							console.log('Action added: UNARCHIVE');
					} else {
						actions.push(EDITOR_ACTIONS.UPDATE_ARCHIVES);
						if (ENABLE_LOGS)
							console.log('Action added: UPDATE_ARCHIVES');
					}
				}
			} else {
				if (draftPost.archives && draftPost.archives.length > 0) {
					actions.push(EDITOR_ACTIONS.ARCHIVE);
					if (ENABLE_LOGS)
						console.log('Action added: ARCHIVE');
				}
			}

			setActions(actions);
			if (ENABLE_LOGS)
				console.log('Final actions:', actions);
		}

		processActions();
	}, [post, draftPost]);
	return { actions, setActions };
};
