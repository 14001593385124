import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { ThemeProvider, createTheme, ThemeOptions, Theme } from '@mui/material/styles';
import { gql, useMutation, useQuery } from '@apollo/client';
import { deepmerge } from '@mui/utils';
import legacyTheme from './Themes/legacy';
// import { UPDATE_THEME_MUTATION, GET_THEME_QUERY } from './graphql/mutations';

// Definir la mutación GraphQL
const UPDATE_THEME_MUTATION = gql`
  mutation UpdateTheme($themeData: AWSJSON!) {
    updateTheme(themeData: $themeData) {
      success
      message
    }
  }
`;

const GET_THEME_QUERY = gql`
  query GetTheme {
    getTheme
  }
`;

// Definir el tipo para el contexto del tema
type ThemeContextType = {
  theme: Theme;
  themeOptions: Parameters<typeof createTheme>[0];
  updateThemeOptions: (updates: Parameters<typeof createTheme>[0]) => void;
};

// Crear el contexto para el tema
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Hook para utilizar el contexto del tema
export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemesConfiguration');
  }
  return context;
};

// Función para guardar el tema en localStorage
const saveThemeToLocalStorage = (theme: Partial<ThemeOptions>) => {
  localStorage.setItem('theme-overrides', JSON.stringify(theme));
};

// Función para cargar el tema desde localStorage
const loadThemeFromLocalStorage = (): Partial<ThemeOptions> => {
  const savedTheme = localStorage.getItem('theme-overrides');
  return savedTheme ? JSON.parse(savedTheme) : {};
};

// Componente proveedor del tema
export const ThemesConfiguration: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Apollo query para obtener el tema guardado en el servidor
  const { data: serverThemeData } = useQuery(GET_THEME_QUERY);

  // Apollo mutation para enviar el tema actualizado al servidor
  const [updateThemeOnServer] = useMutation(UPDATE_THEME_MUTATION);

  // Cargamos el tema guardado de localStorage, si existe
  const [themeOptions, setThemeOptions] = useState<Parameters<typeof createTheme>[0]>(deepmerge(legacyTheme, loadThemeFromLocalStorage()));

  // Sincronizamos la configuración del servidor con el localStorage
  useEffect(() => {
    if (serverThemeData) {
      const serverTheme = serverThemeData.theme;

      // Fusionar el tema del servidor con el tema almacenado localmente
      const mergedTheme = deepmerge(legacyTheme, serverTheme);

      // Guardar la configuración fusionada en localStorage
      saveThemeToLocalStorage(serverTheme);

      // Actualizar el estado del tema con la configuración fusionada
      setThemeOptions(mergedTheme);
    }
  }, [serverThemeData]);

  // Función para actualizar el tema dinámicamente y guardar los cambios en localStorage y servidor
  const updateThemeOptions = async (updates: Partial<Parameters<typeof createTheme>[0]>) => {
    console.log(updates)
    const updatedSavedTheme = deepmerge(loadThemeFromLocalStorage(), updates); // Fusionar con el tema guardado
    const newThemeOptions = deepmerge(legacyTheme, updatedSavedTheme); // Fusionar con el tema original
    console.log(newThemeOptions)
    // Actualizar el estado del tema
    setThemeOptions(newThemeOptions);

    // Guardar el tema actualizado en localStorage
    saveThemeToLocalStorage(updatedSavedTheme);

    // Enviar la actualización del tema al servidor
    try {
      const response = await updateThemeOnServer({
        variables: { theme: updatedSavedTheme },
      });
      console.log('Tema actualizado en el servidor:', response.data);
    } catch (error) {
      console.error('Error al actualizar el tema en el servidor:', error);
    }
  };

  // Memoizamos el tema para evitar recalculaciones innecesarias
  const theme = useMemo(() => createTheme(themeOptions), [themeOptions]);

  // Si el tema está cargando o ha habido un error
  // if (themeLoading) return <div>Cargando tema...</div>;
  // if (themeError) return <div>Error al cargar el tema: {themeError.message}</div>;

  return (
    <ThemeContext.Provider value={{ theme, themeOptions, updateThemeOptions }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider >
  );
};
