import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EDITOR_ACTIONS, usePostEditor } from '../PostEditorProvider';
import { getNewPost } from '../../../Providers/PostsProvider';

const ARE_LOGS_ENABLE = false;

interface PostEditorActionButtonsProps {
  userId: number;
}

const PostEditorActionButtons: React.FC<PostEditorActionButtonsProps> = ({ userId }) => {
  const {
    draftPost: post,
    setDraftPost: setPost,
    handleUpdateArchives,
    handleCreateNewPost,
    handleUpdatePost,
    handleRejectPost,
    handleDeletePost,
    handleAcknowledgeDelete,
    mainBtnRef,
    actions: { actions },
  } = usePostEditor();
  const navigate = useNavigate();
  if (ARE_LOGS_ENABLE)
    console.log('Received actions:', actions);

  const handleMainActionClick = async () => {
    let id: number | undefined = post.id;
    if (ARE_LOGS_ENABLE)
      console.log('Main action clicked, initial post ID:', id);

    if (actions.includes(EDITOR_ACTIONS.CREATE) && post.news_ticker_id != null) {
      console.log('Creating new post...');
      const data = await handleCreateNewPost();
      if (data && data.createPost) {
        id = data.createPost.id;
        if (ARE_LOGS_ENABLE)
          console.log('New post created with ID:', id);
      }
      if (id) await handleUpdateArchives(id);
    } else if (actions.includes(EDITOR_ACTIONS.MODIFY)) {
      if (ARE_LOGS_ENABLE)
        console.log('Modifying existing post...');
      const { data } = await handleUpdatePost();
      if (data && data.post) {
        id = data.post.id;
        if (ARE_LOGS_ENABLE)
          console.log('Post modified with ID:', id);
      }
      if (id) await handleUpdateArchives(id);
    } else {
      if (id) {
        if (ARE_LOGS_ENABLE)
          console.log('Updating archives for post ID:', id);
        await handleUpdateArchives(id);
      }
    }

    if (actions.includes(EDITOR_ACTIONS.CREATE) && post.news_ticker_id != null) {
      if (ARE_LOGS_ENABLE)
        console.log('Navigating to homepage after creating new post');
      setPost(getNewPost());
    } else {
      if (ARE_LOGS_ENABLE)
        console.log('Navigating to homepage after updating post');
      navigate('/');
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      {post.active && (
        <Box sx={{ my: 1, display: 'flex', justifyContent: 'left' }}>
          <Button
            ref={mainBtnRef}
            disabled={post.news_ticker_id === null}
            variant="action"
            color="primary"
            size="large"
            onClick={handleMainActionClick}
          >
            {actions.length > 0 ? actions.join(' & ') : 'Back'}
          </Button>
        </Box>
      )}

      {'id' in post && post.active && !post.rejected && (
        <Box sx={{ my: 1, display: 'flex', justifyContent: 'left' }}>
          <Button variant="action" color="reject" size="large" onClick={handleRejectPost}>
            REJECT
          </Button>
        </Box>
      )}

      {'id' in post && post.active && (
        <Box sx={{ my: 1, display: 'flex', justifyContent: 'left' }}>
          <Button variant="action" color="blue" size="large" onClick={handleDeletePost}>
            DELETE
          </Button>
        </Box>
      )}

      {'id' in post && !post.active && post.aknowledge_delete && post.submit_user_id === userId && (
        <Box sx={{ my: 1, display: 'flex', justifyContent: 'left' }}>
          <Button variant="action" color="blue" size="large" onClick={handleAcknowledgeDelete}>
            Acknowledge
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PostEditorActionButtons;
