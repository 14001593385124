import { InputAdornment, IconButton, FormControl, InputLabel, Input, TextField } from "@mui/material";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useState } from "react";


interface NewsStockValueChangeProps {
  value: string;
  onChange: (value: string) => void;
  onRefresh?: () => void;
}

const StockValueInput: React.FC<NewsStockValueChangeProps> = ({ value, onRefresh, onChange }) => {
  const [componentState, setComponentState] = useState({ editing: 'none' });

  const onEditFn = (event: React.KeyboardEvent<HTMLInputElement>) => {
    var validKeys = ['+', '-', '.', 'Backspace'];

    const input = event.currentTarget;
    const key = event.key;
    const editing = componentState.editing;

    if (editing === 'price' && (!Number.isNaN(key) || validKeys.includes(key))) {

      if (key === 'Tab') {
        setComponentState({ editing: 'change' });
        input.selectionStart = input.value.indexOf(',') + 2;
        input.selectionEnd = input.value.indexOf('%');
        event.preventDefault();
      }
    } else if (editing === 'change' && (!Number.isNaN(key) || validKeys.includes(key))) {
      if (key === 'Tab') {
        document.querySelector<HTMLButtonElement>('#submit-button')?.focus();
        event.preventDefault();
      }
    } else if (input.selectionStart === input.selectionEnd) {
      setComponentState({ editing: 'price' });
      input.selectionStart = 2;
      input.selectionEnd = input.value.indexOf(',');
      event.preventDefault();
    } else {
      event.preventDefault();
    }
  };

  return (
    <TextField
      label="Stock Value"
      size="small"
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      slotProps={{
        htmlInput: {
          onKeyDown: onEditFn,
          onFocus: (event: any) => { onEditFn(event as any); },
          pattern: "\\(\\$\\s*\\d+\\.\\d+,\\s*(\\+|-)\\d+\\.\\d+%\\)",
        },
        input: {
          endAdornment: onRefresh && (
            <InputAdornment position="end">
              <IconButton onClick={onRefresh} edge="end">
                <AutorenewIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      }}

      sx={{ input: { fontSize: '20px' } }}
    />
  );
};

export default StockValueInput;
