import React, { createContext, useState, useContext, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Ticker } from "../../../../Types/Ticker";
import { User } from "../commons/UserAnalystInputSelect";
import { useSettings } from "../../../Settings/SettingsContext";

// Enums for sorting and filtering
export enum SORT_ORDER {
    ASC = 'asc',
    DESC = 'desc'
};

export type PreviewDateAttr = 'updated_at' | 'created_at' | 'news_date';

export enum DATE_ORDER_BY {
    NEWS_DATE_ASC = "news_date|asc",
    NEWS_DATE_DESC = "news_date|desc",
    CREATE_AT_ASC = "created_at|asc",
    CREATE_AT_DESC = "created_at|desc",
    UPDATED_AT_ASC = "updated_at|asc",
    UPDATED_AT_DESC = "updated_at|desc",
};

// Interface for filters
export interface Filters {
    searchClause: string | null;
    ticker: Ticker | null;
    fromPostDate: Dayjs | null;
    untilPostDate: Dayjs | null;
    analyst: User | null;
    orderDatesBy: DATE_ORDER_BY;
    orderAlphabetically: SORT_ORDER | null;
}

// Context for filters
export const FiltersContext = createContext<[Filters, React.Dispatch<React.SetStateAction<Filters>>] | undefined>(undefined);

// Step 1: Initialize filters based on settings, fallback to defaults if necessary
const initializeFilters = (settings: any): Filters => {
    return {
        searchClause: null, // Default search clause
        ticker: null, // Default ticker
        fromPostDate: dayjs().subtract(12, 'months'), // Default range of posts
        untilPostDate: dayjs().add(1, 'day'), // One day in the future
        orderDatesBy: settings?.filtersDefaultDateOrder || DATE_ORDER_BY.UPDATED_AT_DESC, // Use setting or fallback
        orderAlphabetically: null, // Default sorting order
        analyst: null // Default analyst
    };
};

// Step 2: Create a context provider component
const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { settings } = useSettings(); // Use settings from SettingsContext

    // Initialize filters with memoization to avoid recalculating on each render
    const initialFilters = useMemo(() => initializeFilters(settings), [settings]);

    // Use state to manage filters
    const filtersState = useState<Filters>(initialFilters);

    return (
        <FiltersContext.Provider value={filtersState}>
            {children}
        </FiltersContext.Provider>
    );
};

// Hook for accessing FiltersContext safely
export const useFilters = (): [Filters, React.Dispatch<React.SetStateAction<Filters>>] => {
    const context = useContext(FiltersContext);
    if (!context) {
        throw new Error("useFilters must be used within a FiltersProvider");
    }
    return context;
};

export default FiltersProvider;
