import React, { createContext, useState, useContext, ReactNode, useEffect, useMemo, useCallback } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { FEEDS } from '../PostsFeed/Providers/FeedsStateProvider';
import { DATE_ORDER_BY } from '../PostsFeed/Components/Filters/FiltersProvider';

// Global constant for localStorage key
const LOCAL_STORAGE_KEY = 'app-settings';

const GET_SETTINGS = gql`
  query GetSettings {
    settings {
      priceProvider
      filtersDefaultStatus
      filtersDefaultDateOrder
    }
  }
`;

const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($settings: AWSJSON!) {
    updateSettings(settings: $settings) {
      priceProvider
      filtersDefaultStatus
      filtersDefaultDateOrder
    }
  }
`;

interface Settings {
  filtersDefaultStatus: FEEDS;
  filtersDefaultDateOrder: DATE_ORDER_BY;
}

interface SettingsContextProps {
  settings: Settings;
  updateSettings: (newSettings: Partial<Settings>) => void;
}

const SettingsContext = createContext<SettingsContextProps | undefined>(undefined);

export const useSettings = (): SettingsContextProps => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

interface SettingsProviderProps {
  children: ReactNode;
}

const loadSettingsFromLocalStorage = (): Settings => {

  const defaultSettings: Settings = {
    filtersDefaultStatus: FEEDS.PENDING,
    filtersDefaultDateOrder: DATE_ORDER_BY.UPDATED_AT_DESC,
  };


  try {
    const savedSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedSettings ? JSON.parse(savedSettings) : defaultSettings;
  } catch (error) {
    console.error('Failed to load settings from localStorage', error);
    return defaultSettings;
  }
};

export const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = useState<Settings>(loadSettingsFromLocalStorage());

  const [updateSettingsInDB] = useMutation(UPDATE_SETTINGS);

  const { data } = useQuery(GET_SETTINGS, {
    skip: !!settings, // Skip the query if settings are already in localStorage
  });

  useEffect(() => {
    if (data && JSON.stringify(data.settings) !== JSON.stringify(settings)) {
      setSettings(data.settings);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data.settings));
    }
  }, [data, settings]);

  const updateSettings = useCallback(
    (newSettings: Partial<Settings>) => {
      const updatedSettings = { ...settings, ...newSettings };
      setSettings(updatedSettings);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedSettings));

      // Call the mutation to save settings in the database
      updateSettingsInDB({
        variables: {
          settings: JSON.stringify(updatedSettings), // Send the updated settings as a JSON string
        },
      }).catch((error) => {
        console.error('Failed to update settings in the database', error);
      });
    },
    [settings, updateSettingsInDB] // Dependencies for useCallback
  );

  const value = useMemo(() => ({ settings, updateSettings }), [settings, updateSettings]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
